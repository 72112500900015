<template>
  <div>
    <pop-up-layout :title="'Edit Job Priority'">
      <div class="row">
        <div class="col-md-6">
          <v-text-field
            label="title"
            v-bind="basic"
            v-model="form.title"
            placeholder="title"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.text_color"
                clearable
                label="Text Color"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.text_color = null"
                :rules="[util.validation.required]"
              ></v-text-field>
            </template>

            <v-color-picker
              dot-size="25"
              v-model="form.text_color"
              @change="menu2 = false"
              swatches-max-height="200"
            ></v-color-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.background_color"
                clearable
                label="Background Color"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.background_color = null"
                :rules="[util.validation.required]"
              ></v-text-field>
            </template>

            <v-color-picker
              dot-size="25"
              v-model="form.background_color"
              @change="menu1 = false"
              swatches-max-height="200"
            ></v-color-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Sort"
            v-bind="basic"
            placeholder="sort"
            v-model="form.sort"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-12">
          <button type="button" v-on:click="save" class="submit-btn mr-3">
            Submit
          </button>
          <button type="button" class="cancel-btn pull-right" @click="$emit('cancel')">Cancel</button>
        </div>
      </div>
    </pop-up-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import PopUpLayout from "@/components/PopUpLayout.vue";
export default {
  props: ["id", "item"],
  components: {
    PopUpLayout,
  },
  data() {
    return {
      util,
      basic: util.input.basic,
      menu1: false,
      menu2: false,
      form: {
        title: "",
        text_color: "",
        background_color: "",
        sort: 1,
      },
    };
  },
  mounted() {
    this.populateData();
  },
  methods: {
    populateData() {
      this.form.title = this.item.title;
      this.form.text_color = this.item.text_color;
      this.form.background_color = this.item.background_color;
      this.form.sort = this.item.sort;
    },
    save() {
      this.loading = true;
      util
        .http({
          url: "/job-priority/" + this.$props.id,
          method: "PATCH",
          data: { ...this.form },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Successful");
            this.$emit("reload-resource", true);
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
  },
  watch: {
    "$props.id": function() {
      if (this.$props.id) {
        this.populateData();
      }
    },
  },
};
</script>
